export default [
  {
    header: "Menu",
  },
  {
    title: "Venue",
    route: "apps-venue-list",
    icon: "ListIcon",
  },
  {
    title: "noc",
    route: "noc-data",
    icon: "HardDriveIcon",
  },

  {
    title: "Report",
    icon: "FileTextIcon",
    children: [
      {
        title: "Users",
        route: "apps-reports-users",
      },
      {
        title: "Impressions",
        route: "apps-reports-impressions",
      },
      {
        title: "Connections",
        route: "apps-reports-connections",
      },
      {
        title: "Traffic",
        route: "apps-reports-traffic",
      },
      {
        title: "People Analytics",
        route: "apps-reports-people",
      },
    ],
  },

  {
    title: "Admin Users",
    icon: "UserIcon",
    route: "apps-users-list",
  },
  //menu ข้างๆ
  {
    title: "Campaign",
    icon: "AwardIcon",
    children: [
      {
        title: "type Hour ",
        route: "list-hour-code",
        icon: "ClockIcon",
      },
      {
        title: "type Date Time  ",
        route: "list-date-time-code",
        icon: "CalendarIcon",
      },
      // {
      //   title: 'Add Locations',
      //   route: 'add-coed-map',
      // },
    ],
  },
  {
    title: "Festival",
    icon: "CalendarIcon",
    route: "festival",
  },
  {
    title: "Content",
    icon: "TvIcon",
    route: "content",
  },
  {
    title: "game-Top10",
    icon: "FlagIcon",
    route: "game-Top10",
  },
];
