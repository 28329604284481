export default [
  {
    title: "Dashboards",
    route: "apps-reports-people",
    icon: "HomeIcon",
  },

  {
    title: "Hostport AP",
    route: "hotsport_ap",
    icon: "CpuIcon",
  },
  {
    title: "Hostport Gateway",
    route: "hotsport_gateway",
    icon: "CpuIcon",
  },
  {
    title: "Venue Report",
    route: "reports-venue",
    icon: "CpuIcon",
  },
];
